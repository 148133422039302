import React from "react"

// Components
import Container from "../../ui-kit/Container/Container"
import Row from "../../ui-kit/Row/Row"

// Styles
import * as styles from "./PrivacyPage.module.sass"

const PagePrivacy = ({ header, data }) => {
  return (
    <div className={styles.content}>
      <Container>
        <Row>
          <div className={styles.blockContent}>
            <h1>{header}</h1>
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default PagePrivacy
